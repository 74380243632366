import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService, UserSettings } from '@interacta-shared/data-access-auth';
import { Observable } from 'rxjs';
import { forbiddenAccess } from '../routes';

@Injectable()
export class UserSettingsGuard {
    constructor(
        private router: Router,
        private authService: AuthService,
    ) {}

    // at least one data.userSettings must match currentUser.userSettings
    canActivate(
        route: ActivatedRouteSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        const atLeastOne: (keyof UserSettings)[] =
            route.data.userSettings || [];
        const currentUser = this.authService.getCurrentUserData();
        if (
            currentUser &&
            atLeastOne.some((c) => (currentUser.userSettings || {})[c])
        ) {
            return true;
        }
        this.router.navigate([`/${forbiddenAccess}`], {
            skipLocationChange: true,
        });
        return false;
    }
}
